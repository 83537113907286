<template>
  <div>
    <!--    主体律师列表-->
    <div class="app-container">
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
        stripe
      >
<!--        <download-excel-->
<!--          :data   = "tableData"-->
<!--          :fields = "filed"-->
<!--          type    = "csv"-->
<!--          worksheet = "My Worksheet"-->
<!--          name    = "律师信息.xls">-->
<!--          <el-button type="success"> 下载表格  </el-button>-->

<!--        </download-excel>-->
<!--        <4.9 新增>-->
        <el-col :span="4">
          <b>律师列表</b>
        </el-col>
        <!-- 律师姓名搜索框 -->
        <el-col :span="3" :push="1" > 律师姓名:  </el-col>
        <el-col :span="4" :pull="1" >
          <el-input
            placeholder="请输入律师姓名"
            v-model="searchLawyerName"
            size="small"
          >
          </el-input>
        </el-col>
        <!-- 律所名称搜索框 -->
        <el-col :span="3" > 所属律所:  </el-col>
        <el-col :span="4":pull="2" >
          <el-input
            placeholder="请输入律所名称"
            v-model="searchFirmName"
            size="small"
          >
          </el-input>
        </el-col>
        <el-col :span="4" :pull="1">
          <el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
          <el-button size="small" @click="clearInput">重置</el-button>


        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        stripe
        id="table"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background: '#eee', color: '#333' }"
      >
        <el-table-column prop="name" label="姓名" align="center" width="140"></el-table-column>
        <el-table-column prop="dname" label="所属律所" align="center" width="220"></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" width="140"></el-table-column>
        <el-table-column prop="practicingYears" label="执业年限" align="center" width="120"></el-table-column>
        <el-table-column prop="practiceno" label="执业证编号" align="center" ></el-table-column>
        <el-table-column prop="politicalStatus" label="政治面貌" align="center"  ></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="320">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-more"
              @click="handleDetail(scope.$index, scope.row)">
              详情</el-button>
            <el-button
              type="warning"
              size="mini"
              icon="el-icon-connection"
              @click="handleEdit(scope.$index, scope.row)">
              更换律所</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
<!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>
    </div>

<!--    &lt;!&ndash;    屏幕打印检查数据&ndash;&gt;-->
<!--    <h1>修改：{{editForm}}</h1>-->
<!--    <h2>添加：{{addForm}}</h2>-->

    <!--    详情探弹窗-->
    <el-dialog
      :fullscreen="true"
      title="律师详情"
      align="center"
      :visible.sync="ifDetail"
      width="50%">
      <el-row>
        <el-col :span="6" >
          <h3>律师姓名</h3>
          <span>{{currentData.name}}</span>
        </el-col>
        <el-col :span="6" :push="6" >
          <h3>所属律所</h3>
          <span>{{currentData.dname}}</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>

      <el-row>
        <el-col :span="6" >
          <h3>电话号</h3>
          <span>{{currentData.phone}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>性别</h3>
          <span>{{currentData.gender}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>执业编号</h3>
          <span>{{currentData.practiceno}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>执业年限</h3>
          <span>{{currentData.practicingYears}}</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>政治面貌</h3>
          <span>{{currentData.politicalStatus}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>民族</h3>
          <span>{{currentData.nationality}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>学位</h3>
          <span>{{currentData.degree}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>专业</h3>
          <span>{{currentData.specialty}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>身份证号</h3>
          <span>{{currentData.idCard}}</span>
        </el-col>
<!--        <el-col :span="6" :push="6">-->
<!--          <h3>密码</h3>-->
<!--          <span>{{currentData.password}}</span>-->
<!--        </el-col>-->
      </el-row>
    </el-dialog>


    <!--    更换律所信息弹窗-->
    <el-dialog

      align="center"
      :visible.sync="ifEdit"
      :show-close="false"
      width="50%">

<!--      {{editForm}}-->
      <h1>请为{{editForm.name}}选择新律所</h1>
      <el-select style="width:40%" v-model="newFirm" filterable placeholder="请选择">
        <el-option

          v-for="item in firmsList"
          :key="item.id"
          :label="item.dname"
          :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
            <el-button  size="small" @click="ifEdit=false, editForm={}">取消</el-button>
            <el-button  size="small" type="primary" @click="handleEditConfirm">确定</el-button>
          </span>

    </el-dialog>

  </div>
</template>

<script>
  import { lawyers } from '@/utils/export'
  import { request } from '@/network/network'
  import { idCard, phone} from '@/utils/rules'
  import { user_politicalStatus_options,user_politicalStatus_dict,user_degree_dict, user_degree_options ,
   user_gender_options,  user_gender_dict, user_nationality_options , user_nationality_dict, } from '@/utils/dict'

  // import XLSX from 'xlsx'
  // import FileSaver from 'file-saver'

  export default {
    data() {

      return {
        loading: true,
        filed:lawyers,
        searchLawyerName:"",
        searchFirmName:"",
        searchMap:"",
        tableData: [],
        total: 0,
        currentPage: 0,

        currentData:'',  //每行的数据
        //新增律师表单数据
        addForm:{
          name:"",
          did:"",
          politicalStatus:"",
          nationality:"",
          degree:"",
          specialty:"",
          id_card:"",
          gender:"",
          phone:"",
          practiceno:"",
          practicingYears:"",
          password:"",
          id:"",
        },
        //修改信息表单数据
        editForm:{},
        rules:{
          name: [
            { required: true, message: '请输入律师姓名', trigger: 'blur' },
            { min: 1, max: 10, message: '不能多于10字', trigger: 'blur' }
          ],
          idCard:idCard,
          host: [
            { required: true, message: '请输入培训主讲人', trigger: 'blur' }
          ],
          phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
          practiceno:[{ required: true, message: '请输入律师执业证编号', trigger: 'blur' },]

        },
        ifAdd:false,
        ifEdit: false, //是否弹出详情框
        ifDetail:false,
        //政治面貌、性别、学历选择框
        political_status_options: user_politicalStatus_options,
        gender_options: user_gender_options,
        degree_options:  user_degree_options,
        nationality_options: user_nationality_options,
        firmsList: [],
        newFirm:0,
      }
    },
    methods: {
      //导出excel
      exportExcel () {
        /* out-table关联导出的dom节点  */

        var wb = XLSX.utils.table_to_book(document.querySelector('#table')) //table标签绑定的类名
        /* get binary string as output */
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
          FileSaver.saveAs(new Blob([wbout],
            { type: 'application/octet-stream' }), '律师信息.xlsx') //导出文件的名字
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },


      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getLawyers(this.searchMap.name,this.searchMap.dname,val)
      },
      // 重置搜索框
      clearInput() {
        this.searchLawyerName = ""
        this.searchFirmName = ""
        this.searchMap=""
        this.getLawyers(this.searchLawyerName, this.searchFirmName);
      },
      //搜索按钮
      handleSearch(){

        this.searchMap = {
          name: this.searchLawyerName,
          dname: this.searchFirmName
        }
        this.getLawyers(this.searchMap.name,this.searchMap.dname,)
      },
      //详情按钮
      handleDetail(index, row) {
        this.ifDetail = true
        this.currentData = row

      },
      // 新增按钮
      handleAdd(index,row){

        this.ifAdd = true
      },
      // 确定新增
      addSubmit(index,row){
        this.addLawyer(this.addForm)
          .then((res) => {
            this.$message({
              message: "新增成功！",
              type: "success",
              duration: 1000,
            });
            this.getLawyers();
            this.addForm={}
            this.ifAdd = false
          })
          .catch((err) => {
            this.$message({
              message: "发生错误，新增失败！",
              type: "error",
              duration: 1000,
            });
            this.getLawyers();


            this.ifAdd = false
          });
      },

      // 编辑按钮
      handleEdit(index,row){
        this.getFirms();
        this.ifEdit = true
        this.editForm = row //将该条数据传入editForm编辑信息表单中显示
        this.newFirm = row.did

      },
      //确定转所
      handleEditConfirm() {
        if(this.newFirm == this.editForm.did){
          this.$message({
            message: "该律师已在当前律所，请重新选择",
            type: "error",
            duration: 1000,
          });
        }
        else{
          this.$confirm("是否要进行该转所操作?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let form = {"id":this.editForm.id, "did":this.newFirm}
              this.editLawyer(form)
                .then((res) => {
                  this.$message({
                    message: "转所成功！",
                    type: "success",
                    duration: 2000,
                  });
                  this.getLawyers();

                  this.ifEdit = false
                })
                .catch((err) => {
                  this.$message({
                    message: "发生错误，转所失败！",
                    type: "error",
                    duration: 1000,
                  });
                  this.getLawyers();
                  this.ifEdit = false
                });
            })
            .catch(() => {
              this.editForm = {},
                this.newFirm = 0
                this.ifEdit = false
              this.$message({
                message: "操作取消！",
                type: "info",
                duration: 1000,
              });
            });

        }



      },

      // InputNumber 计数器,选择学时
      handleChange(value) {
        this.editForm.practicingYears
        console.log(value);
      },

      // 删除按钮
      handleDelete(index, row) {
        this.$confirm("删除后该律师学时数据也将删除，是否要删除该律师?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.deleteLawyer(row.id)
              .then((res) => {
                    this.$message({
                      message: "删除成功！",
                      type: "success",
                      duration: 1000,
                    });
                this.getLawyers();

              })
              .catch((err) => {
                this.$message({
                  message: "发生错误，删除失败！",
                  type: "error",
                  duration: 1000,
                });
                this.getLawyers();

              });
          })
          .catch(() => {
            row.purchase = false;
            this.$message({
              message: "操作取消！",
              type: "info",
              duration: 1000,
            });
          });
      },
      // dataTran(){
      //
      //   let arr = this.tableData.map(
      //     (item) => {
      //       let  obj = {}
      //     // let obj = lawyer.name
      //       obj = item
      //     console.log("==========")
      //       obj.nationality = user_nationality_dict[item.nationality]
      //
      //     return obj
      //   })
      //
      //
      //
      // },

      getLawyers(name, dname, page){
        return request({
          url: '/user/users',
          method:'get',
          params:{
            name:name,
            dname:dname,
            page: page

          }
        }).then(res => {
          this.total = res.data.total
          let temp = res.data.list
          this.tableData = temp.map(item => {
          item.nationality = user_nationality_dict[item.nationality];
          item.gender = user_gender_dict[item.gender];
          item.politicalStatus = user_politicalStatus_dict[item.politicalStatus];
          item.degree = user_degree_dict[item.degree];
          return item
          })
          this.loading = false
        })
      },
      addLawyer(lawyer){
        return request({
          url: '/user/addUser',
          method: 'post',
          data: lawyer
        })
      },

      editLawyer(lawyer){
        return request({
          url: '/user/updateUser',
          method: 'post',
          data: lawyer
        })
      },
      deleteLawyer(id){
        return request({
          url: '/user/deleteUser',
          method: 'get',
          params: {
            id: id
          }
        })
      },
      getFirms(dname, page){
        return request({
          url: 'department/getALLDepartments',
          method:'get',
          params:{
          }
        }).then(res => {
          this.firmsList = res.data


          //   temp.map(item => {
          //
          //   item.birthday = formatDate(item.birthday)
          //
          //   return item
          // })

        })
      },
    },
    created() {
      this.getLawyers();
      // this.dataTran();

    },
  };
</script>
<style scoped>
  .input-width {
    width: 80%;
  }
</style>
